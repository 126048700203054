import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  img {
    max-width: 350px;
    width: 100%;
    margin-bottom: 1rem;
  }

  a {
    font-family: ${({ theme }) => theme.fonts.secondary}, sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    transition: all .33s;

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;