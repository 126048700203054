import React from 'react';
import { Link } from 'gatsby';

import notFoundBanner from '../images/notfound-banner.png';

import Layout from '../components/Layout';
import { SEO } from '../components/SEO';

import { Container } from '../style/pages/404';

export default function NotFoundPage() {
  return (
    <Layout>
      <Container>
        <img src={notFoundBanner} alt="Not found banner" />
        <Link to="/">
          Take me back to the main page
        </Link>
      </Container>
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)
